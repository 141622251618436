import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Clock, User, ChevronRight, AlertTriangle } from 'lucide-react';
import { usePosts } from '../hooks/useWordPress';
import type { WordPressPost } from '../types/wordpress';
import { testWordPressConnection } from '../services/wordpress';

export default function Blog() {
  const [page] = useState(1);
  const { data: posts, isLoading, error } = usePosts(page);

  useEffect(() => {
    testWordPressConnection().then(success => {
      console.log('[WordPress] Connection test result:', success);
    });
  }, []);

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-32 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4">
          <div className="flex items-center text-red-700">
            <AlertTriangle className="h-5 w-5 mr-2" />
            <p>Error loading blog posts. Please try again later.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Blog</h1>
      <div className="grid gap-8">
        {posts?.map((post: WordPressPost) => (
          <article key={post.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <Link to={`/blog/${post.slug}`} className="block p-6 hover:bg-gray-50">
              <h2 className="text-xl font-semibold mb-2">{post.title.rendered}</h2>
              <div className="text-gray-600 mb-4" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
              <div className="flex items-center text-sm text-gray-500">
                <User className="h-4 w-4 mr-1" />
                <span className="mr-4">Admin</span>
                <Clock className="h-4 w-4 mr-1" />
                <span>{new Date(post.date).toLocaleDateString()}</span>
                <ChevronRight className="h-4 w-4 ml-auto" />
              </div>
            </Link>
          </article>
        ))}
      </div>
    </div>
  );
}